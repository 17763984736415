import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController, ArcElement, TimeScale, TimeSeriesScale } from "chart.js";
import { Chart, Doughnut, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";
import "chartjs-adapter-moment";
import { useEffect, useState } from "react";

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, ArcElement, Legend, Tooltip, LineController, BarController, TimeScale, TimeSeriesScale, ChartDataLabels);

const DailyRecords = ({ data }) => {
    const [recordsData, setRecordsData] = useState(null);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        lineTension: 0.4,
        plugins: {
            legend: {
                position: "top",
            },
            colors: {
                enabled: false,
                forceOverride: true,
            },
            datalabels: {
                align: "end",
                anchor: "end",
                color: "#333333",
                display: "auto",
                font: {
                    size: 11,
                },
            },
        },
        scales: {
            y: {
                type: "linear",
                position: "left",
            },
            y1: {
                type: "linear",
                position: "right", // The secondary yAxis for line chart
            },
        },
    };

    useEffect(() => {
        if (data) handleDailyRecords(data);
    }, [data]);

    const handleDailyRecords = (data) => {
        if (data.suggestedMax1) options.scales.y.suggestedMax = data.suggestedMax1;
        if (data.suggestedMax2) options.scales.y1.suggestedMax = data.suggestedMax2;
        data.datasets.forEach((ds, index) => {
            ds.type = "bar";
        });
        const totalVisitsDataset = {
            type: "line",
            label: "Total des visites",
            data: data.datasets.length > 0 ? arraySum(data.datasets.map((e) => e.data)) : [],
            backgroundColor: "#a48732",
            borderColor: "#a48732",
            fill: false,
            yAxisID: "y1",
        };
        data.datasets.push(totalVisitsDataset);
        setRecordsData(data);
    };

    const arraySum = (arrays) => {
        if (arrays.length === 0) {
            return [];
        }
        const result = new Array(arrays[0].length).fill(0);
        for (let i = 0; i < arrays.length; i++) {
            for (let j = 0; j < arrays[i].length; j++) {
                result[j] += arrays[i][j];
            }
        }
        return result;
    };

    return (
      <>
      {
        recordsData && 
        <Chart type="bar" data={recordsData} options={options} height={350}/>
      }
      </>
    )
};

export default DailyRecords;
