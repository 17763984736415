import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const ErrorDialog = ({error,setError})=>{

    return(
        <Dialog header={()=>(<h3 className="dialog-header">Erreur</h3>)} className="" visible={error} closable={false}>
            <div className="p-grid p-mb-3">
                <div className="p-col-2">
                    <FontAwesomeIcon icon={['fas','exclamation-triangle']} size="3x" color="#f1c811"/>
                </div>
                <div className="p-col-10">{error}</div>
            </div>
            <div className="p-d-flex p-jc-end">
                <Button label="Fermer" icon="pi pi-times-circle" className="p-button-danger" onClick={()=>setError(null)}/>
            </div>            
        </Dialog>
    )
    
}

export default ErrorDialog;