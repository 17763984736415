import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import PrimeReact from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import "./App.css";
import { Breadcrumb } from './components/Breadcrumb';
import moment from 'moment';
import AppSideMenu from './AppSideMenu';
import { AppContext } from './AppContext';
import Dashboard from './components/Dashboard';

const Layout = ({ Content, Title }) => {
    const [menuMode, setMenuMode] = useState('static');
    const [desktopMenuActive, setDesktopMenuActive] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [colorMode, setColorMode] = useState('light');
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
    const [menuTheme, setMenuTheme] = useState('light');
    const [topbarTheme, setTopbarTheme] = useState('blue');
    //const [theme, setTheme] = useState('indigo');
    //const [isInputBackgroundChanged, setIsInputBackgroundChanged] = useState(false);
    const [inlineMenuActive, setInlineMenuActive] = useState({});
    //const [newThemeLoaded, setNewThemeLoaded] = useState(false);
    const [searchActive, setSearchActive] = useState(false)
    let currentInlineMenuKey = useRef(null);

    const [globalFilter, setGlobalFilter] = useState({
        startDate: new Date(moment().subtract(6, 'days')),
        endDate: new Date(),
        storeIds: [],
        locationIds: [1, 2, 3, 4],
        mode: 'jour'
    });

    PrimeReact.ripple = true;

    let searchClick;
    let topbarItemClick;
    let menuClick;
    let inlineMenuClick;

    const menu = [
        {
            items: [
                { label: 'Tableau de bord', icon: ["fas", "home"], to: '/', badgeClassName: 'p-badge-info', roles: ['SUPER_ADMIN', 'ADMIN'], initial: true },
                { label: 'Casiers connectés', icon: ["fas", "table-cells"], to: '/lockers', badgeClassName: 'p-badge-info', roles: ['SUPER_ADMIN', 'ADMIN'], initial: true },
                { label: 'Réservations', icon: ["fas", "tags"], to: '/bookings', badgeClassName: 'p-badge-info', roles: ['SUPER_ADMIN', 'ADMIN'], initial: true },
                { label: 'Utilisateurs', icon: ["fas", "users"], to: '/users', badgeClassName: 'p-badge-info', roles: ['SUPER_ADMIN', 'ADMIN'], initial: true },
            ]
        }
    ];

    const routes = [
        { parent: '', label: '' },
        { parent: 'lockers', label: 'Casiers connectés' }
    ]

    useEffect(() => {
        if (menuMode === 'overlay') {
            hideOverlayMenu()
        }
        if (menuMode === 'static') {
            setDesktopMenuActive(true)
        }
    }, [menuMode])

    /*useEffect(() => {
        //onColorModeChange(colorMode)
    }, [])*/ // eslint-disable-line react-hooks/exhaustive-deps

    const onMenuClick = (event) => {
        menuClick = true;
    }

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isDesktop())
            setDesktopMenuActive((prevState) => !prevState);
        else
            setMobileMenuActive((prevState) => !prevState)

        event.preventDefault();

    }

    const onTopbarItemClick = (event) => {
        console.log(event);
        topbarItemClick = true;
        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null)
        else {
            setActiveTopbarItem(event.item)
        }

        event.originalEvent.preventDefault();
    }

    const onMenuItemClick = (event) => {
        console.log("Menu item click");
        if (!event.item.items && (menuMode === 'overlay' || !isDesktop())) {
            hideOverlayMenu();
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false)
        }
    }

    const onRootMenuItemClick = (event) => {
        setMenuActive((prevState) => !prevState);
    }

    const onRightMenuButtonClick = () => {
        setRightMenuActive((prevState) => !prevState)
    }

    const onMobileTopbarButtonClick = (event) => {
        console.log("On mobile topbar button click");
        setMobileTopbarActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onSearch = () => {

    }

    const onDocumentClick = (event) => {
        if (!searchClick && event.target.localName !== 'input') {
            setSearchActive(false);
        }

        if (!topbarItemClick) {
            setActiveTopbarItem(null);
        }

        if (!menuClick && (menuMode === 'overlay' || !isDesktop())) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
            }
            hideOverlayMenu();
        }

        if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
            let menuKeys = { ...inlineMenuActive };
            menuKeys[currentInlineMenuKey.current] = false;
            setInlineMenuActive(menuKeys);
        }

        if (!menuClick && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }

        searchClick = false;
        topbarItemClick = false;
        inlineMenuClick = false;
        menuClick = false;
    }

    const hideOverlayMenu = () => {
        setMobileMenuActive(false)
        setDesktopMenuActive(false)
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    }

    const isSlim = () => {
        return menuMode === 'slim';
    }

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    const layoutContainerClassName = classNames('layout-wrapper ', 'layout-menu-' + menuTheme + ' layout-topbar-' + topbarTheme, {
        'layout-menu-static': menuMode === 'static',
        'layout-menu-overlay': menuMode === 'overlay',
        'layout-menu-slim': menuMode === 'slim',
        'layout-menu-horizontal': menuMode === 'horizontal',
        'layout-menu-active': desktopMenuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple,
        'layout-rtl': false
    });

    return (

        <div className={layoutContainerClassName + " noselect"} onClick={onDocumentClick}>
            <AppTopbar horizontal={isHorizontal()}
                activeTopbarItem={activeTopbarItem}
                onMenuButtonClick={onMenuButtonClick}
                onTopbarItemClick={onTopbarItemClick}
                onRightMenuButtonClick={onRightMenuButtonClick}
                onMobileTopbarButtonClick={onMobileTopbarButtonClick} mobileTopbarActive={mobileTopbarActive}
                searchActive={searchActive} onSearch={onSearch} />
            <div className="menu-wrapper" onClick={onMenuClick}>
                <div className="layout-menu-container">
                    <AppSideMenu globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                </div>
            </div>
            <div className="layout-main">
                <Breadcrumb />
                <div className="layout-content">
                    {/*<Dashboard globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />*/}
                    <Content globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                </div>
                <AppFooter colorMode={colorMode} />
            </div>
            {mobileMenuActive && <div className="layout-mask modal-in"></div>}
        </div>
    );
}
export default Layout;