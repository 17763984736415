import React, { useContext, useEffect, useRef, useState } from 'react';
import { Panel } from 'primereact/panel';
import AppService from '../service/AppService';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../AppContext';
import moment from 'moment';
import 'chartjs-adapter-moment';
import { ChartHeader } from './ChartHeader';
import DailyRecords from './dashboard/DailyRecords';
import HourlyRecords from "./dashboard/HourlyRecords";
import StoresRecords from "./dashboard/StoresRecords";
import DayLimits from "./dashboard/DayLimits";
import { Dropdown } from 'primereact/dropdown';
import HourlyStoreRecords from './dashboard/HourlyStoreRecords';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressSpinner } from 'primereact/progressspinner';

const Dashboard = ({ globalFilter, setGlobalFilter }) => {

  const MODES = ["jour","semaine"];

  const { user } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const REFRESH_INTERVAL = 30000;
  const [loading, setLoading] = useState(false);
  const [dailyRecords, setDailyRecords] = useState(null);
  const [hourlyRecords, setHourlyRecords] = useState(null);
  const [storesRecords, setStoresRecords] = useState(null);
  const [dayLimits, setDayLimits] = useState(null);

  useEffect(() => {
    if(globalFilter.storeIds.length>0)
      updateDashboard();
    const interval = setInterval(() => updateDashboard(), REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [globalFilter]);

  useEffect(()=>{
    if(dailyRecords&&hourlyRecords&&storesRecords&&dayLimits){      
      const interval = setInterval(() => setLoading(false), 500);
      return () => clearInterval(interval);
    }
  },[dailyRecords,hourlyRecords,storesRecords,dayLimits]);

  const updateDashboard = () => {
    console.log('Updating Dashboard...');
    setLoading(true);
    Promise.all([
      AppService.getDayFlow(
        moment(globalFilter.startDate).format('yyyy-MM-DD'),
        moment(globalFilter.endDate).format('yyyy-MM-DD'),
        globalFilter.storeIds,
        globalFilter.locationIds,
        globalFilter.mode
      ),
      AppService.getHourlyStoreFlows(
        moment(globalFilter.startDate).format('yyyy-MM-DD'),
        moment(globalFilter.endDate).format('yyyy-MM-DD'),
        globalFilter.storeIds,
        globalFilter.locationIds
      ),
      AppService.getStoresRecords(
        moment(globalFilter.startDate).format('yyyy-MM-DD'),
        moment(globalFilter.endDate).format('yyyy-MM-DD'),
        globalFilter.storeIds,
        globalFilter.locationIds
      ),
      AppService.getDayLimits(
        moment(globalFilter.startDate).format('yyyy-MM-DD'),
        moment(globalFilter.endDate).format('yyyy-MM-DD'),
        globalFilter.storeIds,
        globalFilter.locationIds
      )
    ])
      .then(([res1, res2, res3, res4]) => {
        setDailyRecords(res1.data);
        setHourlyRecords(res2.data);
        setStoresRecords(res3.data);
        setDayLimits(res4.data);
      })
      .catch(error => {
        console.log("Erreur lors du chargements des statistiques: ", error);
      });
  }

  const DayOptions = ()=>(
    <Dropdown value={globalFilter.mode} onChange={(e) => setGlobalFilter({...globalFilter,mode:e.value})} options={MODES} placeholder="Selectionner" />
  ) 

  return (
    <>
      {
        loading&&
        <div className="loader">
          <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration=".5s"/>
        </div>
      }
      <div className="p-grid">
        <div className="p-col-12">
          <Panel className='p-mb-2' header={() => (<ChartHeader title="Evolution des visites" Options={<DayOptions/>}/>)} style={{ height: 410 }}>
            {
              dailyRecords&&
              <DailyRecords data={dailyRecords}/>
            }
          </Panel>
        </div>
        <div className="p-col-12">
          <Panel className='p-mb-2' header={() => (<ChartHeader title="Nombre moyen de visiteurs par heure" />)}>
            {
              hourlyRecords &&
              <HourlyStoreRecords data={hourlyRecords} />
            }
          </Panel>
        </div>
        {/*<div className="p-col-12">
          <Panel className='p-mb-2' header={() => (<ChartHeader title="Nombre moyen de visiteurs par heure" />)}>
            {
              hourlyRecords &&
              <HourlyRecords data={hourlyRecords} />
            }
          </Panel>
          </div>*/}
        <div className="p-col-12 p-md-8 p-xl-9">
          <Panel className='p-mb-2' header={() => (<ChartHeader title="Evolution de première et dernière visite par heure" />)}>
            {
              dayLimits &&
              <DayLimits data={dayLimits} />
            }
          </Panel>
        </div>
        <div className="p-col-12 p-md-4 p-xl-3">
          <Panel className='p-mb-2' header={() => (<ChartHeader title="Répartition des visiteurs" />)}>
            {
              storesRecords &&
              <StoresRecords data={storesRecords} />
            }
          </Panel>
        </div>        
      </div>      
    </>
  );
}

export default Dashboard;