import React from "react";

export const ErrorMessage = ({content})=>{
    return(
        <div className="p-message p-component p-message-error p-message-enter-done">
            <div className="p-message-wrapper">
                <span className="p-message-icon pi  pi-times-circle"></span>
                <span className="p-message-detail">{content}</span>
                <button type="button" className="p-message-close p-link">
                    <i className="p-message-close-icon pi pi-times"></i>
                    <span role="presentation" className="p-ink"></span>
                </button>
            </div>
        </div>
    )
}