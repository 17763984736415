import { Panel } from "primereact/panel";
import { Card } from "primereact/card";
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { Tree } from 'primereact/tree';
import { useEffect, useState } from "react";
import FilterHeader from './components/PanelHeader';
import AppService from "./service/AppService";
import moment from "moment";
import ErrorDialog from "./components/dialogs/ErrorDialog";
import { Button } from "primereact/button";

const AppSideMenu = ({ globalFilter, setGlobalFilter }) => {

    const EMPLACEMENTS = [
        { id: 1, name: "Centre ville" },
        { id: 2, name: "Quartier" },
        { id: 3, name: "Périphérie" },
        { id: 4, name: "Centres commerciaux" }
    ];
    const [startDate, setStartDate] = useState(globalFilter.startDate);
    const [endDate, setEndDate] = useState(globalFilter.endDate);
    const [selectedLocations, setSelectedLocations] = useState(globalFilter.locationIds);
    const [storeNodes, setStoreNodes] = useState(null);
    const [selectedTreeNodeKeys, setSelectedTreeNodeKeys] = useState({});
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [selectedStores, setSelectedStores] = useState(globalFilter.storeIds);
    const [error, setError] = useState(null);

    useEffect(() => {
        AppService.getAllSigns()
            .then(res => {
                setSelectedStores(res.data.reduce((acc, currentItem) => {
                    const storeIds = currentItem.magasins.map(magasin => magasin.idMagasin);
                    return [...acc, ...storeIds];
                }, []));
                generateStoreNodes(res.data);
            })
            .catch(error => {
                console.log("Error", error);
            })
    }, []);

    useEffect(() => {
        if (moment(startDate).isAfter(moment(endDate))) {
            setError("Plage de dates Invalide : Date fin doit étre supérieur à la date debut");
        } else {
            if (moment(endDate).diff(moment(startDate), 'days') > 19)
                setGlobalFilter({ ...globalFilter, startDate: startDate, mode: 'semaine' });
            else
                setGlobalFilter({ ...globalFilter, startDate: startDate, mode: 'jour' });
        }
    }, [startDate]);

    useEffect(() => {
        if (moment(startDate).isAfter(moment(endDate))) {
            setError("Plage de dates Invalide : Date fin doit étre supérieur à la date debut");
        } else {
            if (moment(endDate).diff(moment(startDate), 'days') > 19)
                setGlobalFilter({ ...globalFilter, endDate: endDate, mode: 'semaine' });
            else
                setGlobalFilter({ ...globalFilter, endDate: endDate, mode: 'jour' });
        }
    }, [endDate]);

    useEffect(() => {
        if (selectedLocations.length !== 0 || globalFilter.locationIds.length !== 0)
            setGlobalFilter({
                ...globalFilter,
                locationIds: selectedLocations
            });
    }, [selectedLocations]);

    useEffect(() => {
        if (selectedStores.length !== 0 || globalFilter.storeIds.length !== 0) {
            setGlobalFilter({
                ...globalFilter,
                storeIds: selectedStores
            });
        }
    }, [selectedStores]);

    const onLocationsChange = (e) => {
        let selectedValue = [...selectedLocations];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedLocations.indexOf(e.value), 1);

        setSelectedLocations(selectedValue);
    };

    const generateStoreNodes = (signs) => {
        let nodes = { root: [] };
        let keys = {};
        signs.forEach((sign, signIndex) => {
            let signNode = {
                key: `${signIndex}`,
                label: sign.name,
                data: sign,
                icon: 'pi pi-fw pi-tag',
                children: []
            };
            keys[signNode.key] = {
                checked: true,
                partialChecked: false
            };
            sign.magasins.forEach((magasin, index) => {
                signNode.children.push({
                    key: `${signIndex}-${index}`,
                    label: magasin.code,
                    data: magasin,
                    icon: 'pi pi-fw pi-shopping-cart'
                });
                keys[`${signIndex}-${index}`] = {
                    checked: true,
                    partialChecked: false
                };
            });
            nodes.root.push(signNode);
        });
        setSelectedTreeNodeKeys(keys);
        setExpandedKeys(Object.keys(keys));
        setStoreNodes(nodes);
    }

    const onNodeSelect = (e) => {
        const selectedNodeData = e.node.data;
        if (e.node.children) {
            // If the selected node has children, add all their IDs to the selectedNodeIds
            const childNodeIds = e.node.children.map((child) => child.data.idMagasin);
            setSelectedStores((prevSelectedIds) => [...prevSelectedIds, ...childNodeIds]);
        } else {
            setSelectedStores((prevSelectedIds) => [...prevSelectedIds, selectedNodeData.idMagasin]);
        }
    };

    const onNodeUnselect = (e) => {
        const unselectedNodeData = e.node.data;
        if (e.node.children) {
            // If the unselected node has children, remove all their IDs from the selectedNodeIds
            const childNodeIds = e.node.children.map((child) => child.data.idMagasin);
            setSelectedStores((prevSelectedIds) =>
                prevSelectedIds.filter((id) => !childNodeIds.includes(id))
            );
        } else {
            setSelectedStores((prevSelectedIds) =>
                prevSelectedIds.filter((id) => id !== unselectedNodeData.idMagasin)
            );
        }
    };

    const generateFlowReport = () => {
        AppService.getAllFlows().then(res => {
            console.log(res.data);
        });
    }

    return (
        <>
            <div style={{ overflow: "auto" }}>
                <Panel header={() => <FilterHeader title="Intervales" icon="filter" />} className="filter-container-spaced">
                    <div className="p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <Calendar dateFormat="dd-mm-yy" placeholder="Date de début" showButtonBar value={startDate || ''} onChange={e => setStartDate(e.value)}></Calendar>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <Calendar dateFormat="dd-mm-yy" placeholder="Date de fin" showButtonBar value={endDate || ''} onChange={e => setEndDate(e.value)}></Calendar>
                        </div>
                    </div>
                </Panel>
                {/*<Panel header={() => <FilterHeader title="Emplacement" />} className="filter-container">
                <Card>
                    {
                        EMPLACEMENTS?.map((location) => (
                            <div className="p-field-checkbox" key={location.id}>
                                <Checkbox inputId={`location-${location.id}`} name="location" value={location.id} checked={selectedLocations.indexOf(location.id) !== -1} onChange={onLocationsChange}/>
                                <label htmlFor={`location-${location.id}`}>{location.name}</label>
                            </div>
                        ))
                    }
                </Card>
            </Panel>*/}
                <Panel header={() => <FilterHeader title="Boutiques" icon="filter" />} className="filter-container">
                    {
                        storeNodes && selectedTreeNodeKeys &&
                        <Tree
                            value={storeNodes.root}
                            selectionMode="checkbox"
                            selectionKeys={selectedTreeNodeKeys}
                            expandedKeys={expandedKeys}
                            onSelectionChange={(e) => setSelectedTreeNodeKeys(e.value)}
                            onSelect={onNodeSelect}
                            onUnselect={onNodeUnselect}
                            filter
                            filterBy="data.name,data.code"
                        ></Tree>
                    }
                </Panel>
                <Panel header={() => <FilterHeader title="Données" icon="database" />} className="filter-container">
                    <div className="p-col-12">
                        <Button label="Exporter tout" icon="pi pi-file-excel" onClick={() => generateFlowReport()} />
                    </div>
                </Panel>
            </div>
            {error && <ErrorDialog error={error} setError={setError} />}
        </>
    )
}

export default AppSideMenu;