import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController, ArcElement, TimeScale, TimeSeriesScale } from 'chart.js';
import { Chart, Doughnut, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import 'chartjs-adapter-moment';
import { useEffect, useState } from 'react';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale,
    TimeSeriesScale,
    ChartDataLabels
);

const HourlyStoreRecords = ({ data }) => {

    return (
        (data &&
            <Chart
                type='line'
                data={data}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    lineTension: 0.4,
                    plugins: {
                        legend: {
                            display: true
                        },
                        colors: {
                            enabled: false,
                            forceOverride: true
                        },
                        datalabels: {
                            align: 'end',
                            anchor: 'end',
                            //color: '#109bc9',
                            display: 'auto',
                            font: {
                                size: 12
                                //weight: 'bold',
                            },
                        }
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: "Heure",
                            }
                        },
                        y: {
                            title: {
                                display: true,
                                text: "Nombre de visites",
                            },
                            //suggestedMin: 0,
                            //suggestedMax: data ? data.suggestedMax1 : null
                        }
                    }
                }}
                height={300}
            />
        )
    )

}

export default HourlyStoreRecords;