import axios from "axios";

class UserService{
    constructor(){
        this.api = axios.create({
            baseURL:process.env.REACT_APP_API_BASE_URL,
            headers:{
                "Content-Type":"application/json"
            }
        });
    }

    login(username,password){
        return this.api.post("/auth",{username:username,password:password});
    }

    register(data){
        return this.api.post("/accounts/signup",data);
    }

    logout(){
        localStorage.removeItem('user');
        window.location.reload();
    }
}

export default new UserService();