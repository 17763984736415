import moment from "moment/moment";
import api from "../api";
import UserService from "./UserService";

class AppService {
    constructor() {
        this.api = api;
        this.api.interceptors.request.use(
            function (config) {
                const user = JSON.parse(localStorage.getItem('user'));
                //if( moment(user.magicLinkExpiry).isBefore(moment(new Date())))
                //UserService.logout();
                if (user)
                    config.headers["Authorization"] = 'Bearer ' + user.token;
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
        this.api.interceptors.response.use(response => {
            return response;
        }, error => {
            console.log("Intercepted error: ", error);
            if (error.response.status === 401) {
                UserService.logout();
            }
            return error;
        });
    }
    //Dashboard services
    getDayFlow(startDate, endDate, storeIds, empIds, mode) {
        console.log('Fetching day flows...');
        const params = {
            start_date: startDate,
            end_date: endDate,
            store_ids: storeIds.length > 0 ? storeIds.join(',') : '',
            emp_ids: empIds.length > 0 ? empIds.join(',') : '',
            mode: mode
        };
        return this.api.get('/flux/day', { params: params });
    }
    getHourlyRecords(startDate, endDate, storeIds, locationIds) {
        return this.api.get('/flux/hour', {
            params: {
                start_date: startDate,
                end_date: endDate,
                store_ids: storeIds.length > 0 ? storeIds.join(',') : '',
                emp_ids: locationIds.length > 0 ? locationIds.join(',') : ''
            }
        });
    }
    getHourlyStoreFlows(startDate, endDate, storeIds, locationIds) {
        return this.api.get('/flux/hour/store', {
            params: {
                start_date: startDate,
                end_date: endDate,
                store_ids: storeIds.length > 0 ? storeIds.join(',') : '',
                emp_ids: locationIds.length > 0 ? locationIds.join(',') : ''
            }
        });
    }
    getStoresRecords(startDate, endDate, storeIds, locationIds) {
        return this.api.get('/flux/store', {
            params: {
                start_date: startDate,
                end_date: endDate,
                store_ids: storeIds.length > 0 ? storeIds.join(',') : '',
                emp_ids: locationIds.length > 0 ? locationIds.join(',') : ''
            }
        });
    }
    getDayLimits(startDate, endDate, storeIds, locationIds) {
        return this.api.get('/flux/limits', {
            params: {
                start_date: startDate,
                end_date: endDate,
                store_ids: storeIds.length > 0 ? storeIds.join(',') : '',
                emp_ids: locationIds.length > 0 ? locationIds.join(',') : ''
            }
        });
    }
    getAllFlows() {
        return this.api.get('/flux/all');
    }
    //Location Services
    getAllLocations() {
        return this.api.get("/locations");
    }
    //Sign Service
    getAllSigns() {
        return this.api.get("/enseignes");
    }
}

export default new AppService();