import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController, ArcElement, TimeScale, TimeSeriesScale } from 'chart.js';
import { Chart, Doughnut, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import 'chartjs-adapter-moment';
import { useEffect, useState } from 'react';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale,
    TimeSeriesScale,
    ChartDataLabels
);

const StoresRecords = ({data}) => {
    return (
        (
        data&&
        <Doughnut
            data={data}
            height={300}
            options={{
                plugins: {
                    datalabels: {
                        color: '#fff',
                        formatter: (value, ctx) => {
                            const dataset = ctx.chart.data.datasets[ctx.datasetIndex];
                            const total = dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue);
                            const percentage = Math.round(((value / total) * 100));
                            return `${percentage}%`;
                        },
                    },
                    legend: {
                        display: false
                    }
                }
            }}
        />
        )
    )
}

export default StoresRecords;