import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import AppService from "../service/AppService";
import { Button } from "primereact/button";
import moment from "moment/moment";

const Report = ({ globalFilter, setGlobalFilter }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const dt = useRef(null);

    useEffect(() => {
        setLoading(true);
        AppService.getAllFlows()
            .then((res) => {
                setData(res.data);
                console.log(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const rightToolbarTemplate = () => {
        return <Button label="Exporter vers Excel" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(
                dt.current.props.value.map((item) => ({
                    Magasin: item.magasin,
                    Jour: moment(item.jour).format("DD/MM/yyyy"),
                    Heure: item.heure,
                    Visiteurs: item.visiteurs,
                }))
            );
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });

            saveAsExcelFile(excelBuffer, "flows");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                let EXCEL_EXTENSION = ".xlsx";
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE,
                });

                module.default.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    return (
        <div className="p-grid table-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toolbar left={() => <h3>Rapport de tous les flux</h3>} className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>
                    <DataTable
                        ref={dt}
                        value={data}
                        paginator
                        className="p-datatable-customers"
                        rows={10}
                        dataKey="id"
                        rowHover
                        //selection={selectedCustomers}
                        //onSelectionChange={(e) => setSelectedCustomers(e.value)}
                        //globalFilter={globalFilter1}
                        emptyMessage="Aucun flux pour la période sélectionnée."
                        loading={loading}
                        //header={customer1TableHeader}
                    >
                        <Column field="magasin" header="Magasin"></Column>
                        <Column field="jour" header="Jour"></Column>
                        <Column field="heure" header="Heure"></Column>
                        <Column field="visiteurs" header="Visiteurs"></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default Report;
