import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Layout from "./Layout";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";
import 'moment/locale/fr';
import UserService from "./service/UserService";
import { AppContext } from "./AppContext";
import moment from "moment";
import Report from "./components/Report";
moment().locale('fr-FR');

const App = () => {

    library.add(fas, fab, far);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    let history = useHistory();
    let location = useLocation();

    useEffect(() => {
        if (!user) history.replace('/login');
    }, []);

    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        }
    }, [user]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    function PrivateRoute({ children, roles, ...rest }) {
        const authenticated = user !== null;
        return (
            user &&
            <Route {...rest} render={({ location }) => {
                if (roles.includes(user.roles[0]))
                    return children;
                else
                    return <Redirect to="/access" />;
            }} />//:
            //<Redirect to={{pathname:"/login",state:{from:location}}}/>
        );
    }

    return (
        <AppContext.Provider value={{ user, setUser }}>
            <Switch>
                <PrivateRoute exact path="/" roles={['MANAGER', 'ADMIN']}><Layout Title="Tableau de bord" Content={Dashboard} /></PrivateRoute>
                <PrivateRoute exact path="/reports" roles={['MANAGER', 'ADMIN']}><Layout Title="Rapport toutes les données" Content={Report} /></PrivateRoute>
                <Route path="/login"><Login /></Route>
                <Route path="/error" component={Error} />
                <Route path="/notfound" component={NotFound} />
                <Route path="/access"><Access /></Route>
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </AppContext.Provider>
    )

}

export default App;