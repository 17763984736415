import React, { useContext } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import img_logo from './img/logo.png';

const AppFooter = (props) => {

    return <div className="layout-footer p-d-flex p-ai-center p-px-4 p-py-2 p-shadow-2">
        <img id="footer-logo" src={img_logo} alt="sonar-footer-logo" style={{height:'7vh',width:'auto'}}/>
        <Button type="button" icon="pi pi-github fs-large" className={classNames('p-button-rounded p-button-text p-button-plain', { 'p-ml-auto p-mr-2': true, 'p-ml-2 p-mr-auto': false })}></Button>
        <Button type="button" icon="pi pi-facebook fs-large" className={classNames('p-button-rounded p-button-text p-button-plain', { 'p-mr-2': true, 'p-ml-2': false })}></Button>
        <Button type="button" icon="pi pi-twitter fs-large" className={classNames('p-button-rounded p-button-text p-button-plain', { 'p-mr-2': true, 'p-ml-2': false })}></Button>
    </div>

}

export default AppFooter;