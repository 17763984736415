import React, { useContext, useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useHistory, useLocation } from 'react-router-dom';
import { ErrorMessage } from './ErrorMessage';
import { useFormik } from 'formik';
import * as yup from "yup";
import axios from 'axios';
import img_logo from '../img/logo2.png'
import { Loader } from './dialogs/Loader';
import UserService from '../service/UserService';
import { AppContext } from '../AppContext';

const Login = () => {
	const {user,setUser} = useContext(AppContext);
	const [error,setError] = useState(null);
	const [success,setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
    let history = useHistory();
    let state = useLocation();

	let formik = useFormik({
        initialValues: {
			username : '',
			password: ''
		},
        validationSchema: yup.object().shape({
			username: yup.string().required("Veuillez saisir votre nom d'utilisateur"),
			password: yup.string().required('Veuillez saisir votre mot de passe')
        }),
        onSubmit: (values, { setSubmitting }) => {
            setTimeout(async () => {
				setLoading(true);
				UserService.login(values.username,values.password)
				.then(res=>{
					console.log("Login result",res.data);
					setError(null);
					setUser(res.data);
					history.push('/');
				}) 
				.catch(error=>{
					console.log("Erreur lors de l'authentification: ",error);
					if (error.response) {
						setError(error.response.data);
					} else {
						setError(error.message);
					}
				})
				.finally(()=>{
					setLoading(false);
				});
				setSubmitting(false);
			},400);
		}
    });

	return (
		<>
			<div className="pages-body login-page p-d-flex p-flex-column">
				<div className="p-as-center p-mt-auto p-mb-auto">
					<div className="pages-panel card p-d-flex p-flex-column login-container">
						<form onSubmit={formik.handleSubmit}>
							<img src={img_logo}/>
							<h4 className='p-mt-1'>Bienvenue</h4>
							<div className="pages-detail p-mb-6 p-px-6">Pour accéder à cette application vous devez être authentifié</div>
							<div className="input-panel p-d-flex p-flex-column p-px-3">
								<div className="p-inputgroup p-mb-3">
									<span className="p-inputgroup-addon">
										<i className="pi pi-user"></i>
									</span>
									<span className="p-float-label">
										<InputText type="text" id="inputgroup1" name="username" className={formik.errors.username&&formik.submitCount>0?'p-invalid':''} onChange={formik.handleChange} value={formik.values.username||''}/>
										<label htmlFor="inputgroup1">Votre nom d'utilisateur</label>
									</span>
								</div>
								{formik.submitCount>0 && <span className="field-error">{formik.errors.username}</span>}
								<div className="p-inputgroup">
									<span className="p-inputgroup-addon">
										<i className="pi pi-key"></i>
									</span>
									<span className="p-float-label">
										<InputText type="password" id="password" name="password" className={formik.errors.password&&formik.submitCount>0?'p-invalid':''} onChange={formik.handleChange} value={formik.values.password||''}/>
										<label htmlFor="password">Votre mot de passe</label>
									</span>
								</div>
								{formik.submitCount>0 && <span className="field-error">{formik.errors.password}</span>}
								<Button type='submit' icon="pi pi-sign-in" className=" p-mt-3 p-mb-4 p-px-3" label="Connexion"></Button>
							</div>														
						</form>	
						{
							success&&
							<p style={{color:'green',fontSize:'1.1rem',fontWeight:'bold'}}>Vous êtes déconnecté avec succès.</p>
						}		
						{
							error&&
							<p style={{color:'red',fontSize:'1.1rem',fontWeight:'bold'}}>{error}</p>
						}				
					</div>
				</div>
			</div>
			{/*<Loader visible={loading}/>*/}
		</>
	)
}

export default Login;
