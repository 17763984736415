import React, { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AppContext } from './AppContext';
import img_user from "./img/user.png";
import logo_white from "./img/logo_white.png";
import AppService from './service/AppService';
import UserService from './service/UserService';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AppTopbar = (props) => {
    const { user, setUser } = useContext(AppContext);
    const history = useHistory();

    // Fixed for 6.1.0
    // eslint-disable-next-line
    const searchPanel = useRef(null)

    useEffect(() => {
        // Fixed for 6.1.0
        /*if (props.searchActive) {
            searchPanel.current.element.focus();
        }*/
    }, [props.searchActive])

    /*const logout = ()=>{
        localStorage.removeItem("user");
        setUser(null);
    }*/

    const goToDashboard = () => {
        history.push('/');
    }

    const logout = () => {
        /*AppService.validateMagicLinkToken(user.magicLinkToken);
        setUser(null);
        localStorage.removeItem('user');
        history.push('/login');*/
        UserService.logout();
    }

    return (
        <div className="layout-topbar p-shadow-4">
            <div className="layout-topbar-left">
                <button type="button" style={{ cursor: 'pointer' }} className="layout-topbar-logo p-link" onClick={() => history.push('/')}>
                    <img id="app-logo" src={logo_white} alt="ultima-layout" style={{ height: '3.1rem' }} />
                </button>
                <button type="button" className="layout-menu-button p-shadow-6 p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-right"></i>
                </button>
                <button type="button" className="layout-topbar-mobile-button p-link">
                    <i className="pi pi-ellipsis-v fs-large" onClick={props.onMobileTopbarButtonClick}></i>
                </button>
            </div>

            <div className={classNames('layout-topbar-right', { 'layout-topbar-mobile-active': props.mobileTopbarActive })}>
                <div className="layout-topbar-actions-left">
                </div>
                <div className="layout-topbar-actions-right">
                    <ul className="layout-topbar-items">
                        <li className="layout-topbar-item app p-mr-5">
                            <Button label='Tableau de bord' icon="pi pi-desktop" className="layout-topbar-action p-link" onClick={goToDashboard} />
                        </li>
                        <li className="layout-topbar-item app p-mr-5">
                            <button className="layout-topbar-action rounded-circle p-link" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: 'apps' })}>
                                <i className="pi pi-file fs-large p-mr-3"></i><span>Rapports</span>
                            </button>
                            <CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'apps'} unmountOnExit>
                                <div className="layout-topbar-action-panel p-shadow-6">
                                    <div className="p-grid p-nogutter">
                                        {/*<div className="layout-topbar-action-item p-col-6">
                                            <a href="files/sonar-agent-win.zip" className="p-d-flex p-ai-center p-flex-column text-color p-link">
                                                <i className="pi pi-filter action indigo-bgcolor white-color"></i>
                                                <span>Données filtrées</span>
                                            </a>
                                        </div>*/}
                                        <div className="layout-topbar-action-item p-col-12">
                                            <Link to="/reports" className="p-d-flex p-ai-center p-flex-column text-color p-link">
                                                <span className="action teal-bgcolor white-color">
                                                    <FontAwesomeIcon icon={['fas', 'database']} style={{ fontSize: '24px' }} />
                                                </span>
                                                <p>Toutes les données</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </CSSTransition>
                        </li>
                    </ul>
                </div>
                <div className="layout-topbar-actions-right">
                    <ul className="layout-topbar-items">
                        {/*<li className="layout-topbar-item notifications">
                            <button className="layout-topbar-action rounded-circle p-link" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: 'notifications' })}>
                                <span className="p-overlay-badge">
                                    <i className="pi pi-bell fs-large"></i>
                                    <span className="p-badge p-badge-warning p-badge-dot"></span>
                                </span>
                            </button>

                            <CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'notifications'} unmountOnExit>
                                <ul className="layout-topbar-action-panel p-shadow-6 fadeInDown">
                                    <li className="p-mb-3">
                                        <span className="p-px-3 fs-small">You have <b>4</b> new notifications</span>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <div className="p-d-flex p-flex-row p-ai-center">
                                            <img src={`${process.env.PUBLIC_URL}/assets/demo/images/avatar/avatar-1.png`} alt="" />
                                            <div className={classNames('p-d-flex p-flex-column', { 'p-ml-3': true, 'p-mr-3': false })} style={{ flexGrow: '1' }}>
                                                <div className="p-d-flex p-ai-center p-jc-between p-mb-1">
                                                    <span className="fs-small p-text-bold">Jerome Bell</span>
                                                    <small>42 mins ago</small>
                                                </div>
                                                <span className="fs-small">How to write content about your photographs?</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <div className="p-d-flex p-flex-row p-ai-center">
                                            <img src={`${process.env.PUBLIC_URL}/assets/demo/images/avatar/avatar-2.png`} alt="" />
                                            <div className={classNames('p-d-flex p-flex-column', { 'p-ml-3': true, 'p-mr-3': false })} style={{ flexGrow: '1' }}>
                                                <div className="p-d-flex p-ai-center p-jc-between p-mb-1">
                                                    <span className="fs-small p-text-bold">Cameron Williamson</span>
                                                    <small>48 mins ago</small>
                                                </div>
                                                <span className="fs-small">Start a blog to reach your creative peak.</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <div className="p-d-flex p-flex-row p-ai-center">
                                            <img src={`${process.env.PUBLIC_URL}/assets/demo/images/avatar/avatar-3.png`} alt="" />
                                            <div className={classNames('p-d-flex p-flex-column', { 'p-ml-3': true, 'p-mr-3': false })} style={{ flexGrow: '1' }}>
                                                <div className="p-d-flex p-ai-center p-jc-between p-mb-1">
                                                    <span className="fs-small p-text-bold">Anna Miles</span>
                                                    <small>1 day ago</small>
                                                </div>
                                                <span className="fs-small">Caring is the new marketing</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <div className="p-d-flex p-flex-row p-ai-center">
                                            <img src={`${process.env.PUBLIC_URL}/assets/demo/images/avatar/avatar-4.png`} alt="" />
                                            <div className={classNames('p-d-flex p-flex-column', { 'p-ml-3': true, 'p-mr-3': false })} style={{ flexGrow: '1' }}>
                                                <div className="p-d-flex p-ai-center p-jc-between p-mb-1">
                                                    <span className="fs-small p-text-bold">Arlene Mccoy</span>
                                                    <small>4 day ago</small>
                                                </div>
                                                <span className="fs-small">Starting your traveling blog with Vasco.</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </CSSTransition>
                        </li>*/}
                        <li className="layout-topbar-item">
                            <button className="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2 rounded-circle p-link" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: 'profile' })}>
                                <img src={img_user} alt="avatar" style={{ width: '32px', height: '32px' }} />
                            </button>
                            <CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
                                <ul className="layout-topbar-action-panel p-shadow-6">
                                    <li className="layout-topbar-action-item" id="item-logout" onClick={() => logout()}>
                                        <button className="p-d-flex p-flex-row p-ai-center p-link" id="btn-logout">
                                            <i className={classNames('pi pi-power-off', { 'p-mr-2': true, 'p-ml-2': false })}></i>
                                            <span>Déconnexion</span>
                                        </button>
                                    </li>
                                </ul>
                            </CSSTransition>
                        </li>
                        {/*<li className="layout-topbar-item">
                            <button type="button" className="layout-topbar-action rounded-circle p-link" onClick={props.onRightMenuButtonClick}>
                                <i className="pi fs-large pi-arrow-left"></i>
                            </button>
                        </li>*/}
                    </ul>
                </div>
            </div>
        </div >
    );

}

export default AppTopbar;
