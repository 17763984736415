import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'prismjs/themes/prism-coy.css';
import App from "./App";

ReactDOM.render(
    <HashRouter>
		<App/>
    </HashRouter>,
    document.getElementById('root')
);
