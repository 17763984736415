import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";

export const Breadcrumb = ({title}) => {

    const toggleFullScreen = ()=>{
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch(err => {
              console.log(`Error attempting to enable full-screen mode: ${err.message}`);
            });
        } else {
            if (document.exitFullscreen) {
              document.exitFullscreen();
            }
        }
    }

    return (
        <div className="layout-breadcrumb-container p-d-flex p-jc-between p-ai-center p-shadow-1">
            <nav className="p-breadcrumb p-component layout-breadcrumb p-pl-4 p-py-2" aria-label="Breadcrumb">
                <ul>
                    <li className="p-breadcrumb-home">
                        <a href="/" className="p-menuitem-link">
                            <span className="p-menuitem-icon pi pi-home"></span>
                        </a>
                    </li>
                    <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>
                    <li className="">
                        <a href="#" className="p-menuitem-link">
                            <span className="p-menuitem-text">{title}</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <div className="layout-breadcrumb-buttons p-d-flex p-ai-center p-pr-3">
                <Button icon ={()=><FontAwesomeIcon icon={['fas','maximize']} style={{fontSize:'18px', color:'#999'}}/>} className="p-button-rounded p-button-text p-mr-2 p-mb-2" tooltip="Mode plein écran" tooltipOptions={{position:'top'}} onClick={()=>toggleFullScreen()}>
                    
                </Button>
            </div>
        </div>
    )
}