import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const ChartHeader = ({title,Options})=>{
    return(
        <div className='chart-header elevation-2'>
            <div className="p-d-flex p-jc-between" style={{width:'100%'}}>
                <div className='chart-title'>
                    <FontAwesomeIcon icon={['fas','chart-simple']} className='p-mr-2'/>
                    <span>{title}</span>
                </div>
                <div>{Options}</div>
            </div>
            
        </div>
    )
}