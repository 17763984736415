import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PanelHeader({ title, icon }) {
    return (
        <div className="chart-header elevation-2">
            <p className="chart-title">
                <FontAwesomeIcon icon={["fas", icon]} className="p-mr-2" />
                <span>{title}</span>
            </p>
        </div>
    );
}
