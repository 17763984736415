import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController, ArcElement, TimeScale, TimeSeriesScale } from 'chart.js';
import { Chart, Doughnut, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import 'chartjs-adapter-moment';
import { useEffect, useState } from 'react';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale,
    TimeSeriesScale,
    ChartDataLabels
);

const DayLimits = ({data}) => {
    return(
        (data&&
        <Line
            data={data}
            height={400}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                lineTension: 0.3,
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: "day", // Show the date in the format "Jan 01"
                            displayFormats: {
                                day: "DD MMM",
                            },
                        },
                        title: {
                            display: true,
                            text: "Période",
                        }
                    },
                    y: {
                        type: 'time',
                        time: {
                            parser: 'HH:mm',
                            unit: "minute", // Show the date in the format "Jan 01"
                            displayFormats: {
                                minute: "HH:mm",
                            },
                            tooltipFormat: 'HH:mm'
                        },
                        title: {
                            display: true,
                            text: "Heure",
                        },
                        suggestedMax: "23:59",
                        suggestedMin: "06:00"
                    }
                },
                plugins: {
                    legend: {
                        position: 'right',
                    },
                    datalabels: {
                        display: false, // Hide the data labels on data points
                    },
                }
            }}
        />
        )
    )
}

export default DayLimits;